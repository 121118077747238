/* eslint-disable no-shadow */
import { createEmailInfo } from '@/api/email';
import { getReport, createReportingPayload } from '@/utils/email';
import { sendUserResult, reportTest, reportRating } from '@/utils/email/ses';
import { now } from '@/utils/time';

function defaultState() {
  return {
    email: null,
  };
}

const state = defaultState();

const getters = {
  email: (state) => state.email,
};

const mutations = {
  reset(state) {
    Object.assign(state, defaultState());
  },
  setEmail(state, payload) {
    state.email = payload;
  },
};

const actions = {
  reset({ commit }) {
    commit('reset');
  },
  setEmail({ commit }, payload) {
    commit('setEmail', payload);
  },
  async postEmailData() {
    try {
      const { sessionId } = this.state.testMicro;
      const data = {
        uid: sessionId,
        timestamp: now(),
      };
      await createEmailInfo(data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async sendUserEmail(state, payload) {
    try {
      await sendUserResult(payload);
    } catch (error) {
      throw new Error(error.text);
    }
  },
  async sendTestReportingEmail() {
    const { id, choiceJourney } = this.state.testMicro;
    const { localeCountry } = this.state.app;
    const additive = this.getters['testMacro/additive'];
    const data = getReport(choiceJourney, id, additive);
    const payload = createReportingPayload(id, localeCountry, data);
    try {
      reportTest(payload);
    } catch (error) {
      throw new Error(error);
    }
  },
  async sendRatingReportingEmail() {
    const { id } = this.state.testMicro;
    const { localeCountry } = this.state.app;
    const data = {
      stars: this.state.rating.stars['1'].stars,
      text: this.state.rating.text,
    };
    const payload = createReportingPayload(id, localeCountry, data);
    try {
      reportRating(payload);
    } catch (error) {
      throw new Error(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
