import Vue from 'vue';
import VueRouter from 'vue-router';
import { i18nUtils } from '@/utils/i18n';
import lazyLoad from '@/utils/lazyLoad';
// little hack... preserve original URL but add '404' which triggers path '*'
// use route404 alternatively which routes to '/'
import { path404 } from '@/utils/router';
import { redirectProfeminaTest } from '@/utils/redirect';
import store from '@/store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/:localeCountry',
    component: {
      template: '<router-view></router-view>',
    },
    children: [
      {
        path: '/',
        name: 'Home',
        component: lazyLoad('views/Home/Index'),
      },
      {
        path: ':test',
        name: 'Test',
        component: lazyLoad('views/Test/Index'),
        beforeEnter: (to, from, next) => {
          function isValid(testId) {
            const allTestIds = store.getters['testMacro/all'];
            return allTestIds.includes(testId);
          }
          const { localeCountry, test: testId } = to.params;
          if (!isValid(testId)) {
            return next(path404(localeCountry, testId));
          }
          return next();
        },
      },
      {
        path: ':test/result',
        name: 'Result',
        component: lazyLoad('views/Result/Index'),
        beforeEnter: (to, from, next) => {
          const noData = store.getters['testMicro/choiceJourney'].length === 0;
          if (noData) {
            const { test } = to.params;
            return next({ name: 'Test', params: { test } });
          }
          return next();
        },
      },
      {
        path: 'info',
        name: 'Info',
        component: lazyLoad('views/PostTest/Index'),
      },
      {
        path: 'done',
        name: 'Done',
        component: lazyLoad('views/Done/Index'),
      },
      {
        path: '*',
        name: 'NotFound',
        component: lazyLoad('views/NotFound/Index'),
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const { localeCountry, test: testId } = to.params;

  // ELEA unwinding: reroute back to PF
  if (to.name === 'Test') {
    if (redirectProfeminaTest(localeCountry, testId)) {
      return undefined; // Stop further routing since we are redirecting
    }
  }
  store.dispatch('app/view', to.name);

  store.dispatch('app/localeCountry', localeCountry);
  // Extract locale from localeCountry (works for format "locale-country" as well "just "locale")
  const locale = localeCountry ? localeCountry.split('-')[0] : null;
  // Checks if the route's param is supported, if not, redirects to the first supported one.
  if (!i18nUtils.isLocaleSupported(locale)) {
    // return next(path404(locale, testId));
    const userLocale = i18nUtils.getUserSupportedLocale();
    i18nUtils.loadLocale(userLocale).then(() => next(userLocale));
  }
  i18nUtils.loadLocale(locale).then(() => {
    if (to.name === 'Test') store.dispatch('testMicro/initiateTest', testId);
  });
  if (['Home', 'Info'].includes(to.name)) {
    store.dispatch('testMicro/reset');
  }
  return next();
});

export default router;
