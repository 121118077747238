/* eslint-disable no-shadow */
import {
  createTestData,
  createTestInfo,
} from '@/api/user';
import { now } from '@/utils/time';

function defaultState() {
  return {
    testData: [],
    testInfo: {},
  };
}

const state = defaultState();

const getters = {
  testData: (state) => state.testData,
  testInfo: (state) => state.testInfo,
};

const mutations = {
  reset(state) {
    Object.assign(state, defaultState());
  },
  setTestData(state, payload) {
    state.testData = payload;
  },
  setTestInfo(state, payload) {
    state.testInfo = payload;
  },
};

const actions = {
  async postUserData({ commit }) {
    try {
      const {
        id, sessionId, choiceJourney, timestamps,
      } = this.state.testMicro;
      const { addressHash } = this.state.ip;
      const { localeCountry } = this.state.app;

      // send testInfo
      const info = {
        uid: sessionId,
        timestamp: now(),
        ip_address: addressHash,
        test_id: id,
        locale: localeCountry,
      };
      commit('setTestInfo', info);
      await createTestInfo(info);

      // send testData
      const data = choiceJourney.map((choice, i) => ({
        uid: sessionId,
        ts_start_question: timestamps.startQuestion[i],
        ts_click_choice: timestamps.choice[i],
        ts_end_advice: timestamps.endAdvice[0],
        question: i.toString(),
        choice,
      }));
      commit('setTestData', data);
      await createTestData(data);
    } catch (error) {
      throw new Error(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
