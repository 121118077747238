/* eslint-disable no-shadow */
function defaultState() {
  return {
    notification: null,
  };
}

const state = defaultState();

const getters = {
  notification: (state) => state.notification,
};

const mutations = {
  reset(state) {
    Object.assign(state, defaultState());
  },
  notify(state, payload) {
    state.notification = payload;
    setTimeout(() => { this.commit('notification/reset'); }, 3000);
  },
};

const actions = {
  reset({ commit }) {
    commit('reset');
  },
  notify({ commit }, payload) {
    commit('notify', payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
