import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persistedstate';

import app from '@/store/modules/app';
import notification from '@/store/modules/notification';
import ip from '@/store/modules/ip';
import user from '@/store/modules/user';
import testMacro from '@/store/modules/testMacro';
import testMicro from '@/store/modules/testMicro';
import email from '@/store/modules/email';
import rating from '@/store/modules/rating';

Vue.use(Vuex);

const settings = {
  key: 'profemina-app',
  storage: window.sessionStorage,
};

const vuexLocalStorage = new (VuexPersist)(settings);

const strict = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    app,
    notification,
    ip,
    user,
    testMacro,
    testMicro,
    email,
    rating,
  },
  strict,
  plugins: [vuexLocalStorage],
});
