import routes from '@/mappings/pfRoutes';
import testRedirects from '@/mappings/pfTestRedirects';

const pfUrl = 'https://www.profemina.org';

const redirect = (url) => (window.location.replace(url));

const splitLocaleCountry = (localeCountry) => {
  let locale;
  let country;
  if (localeCountry.split('-').length === 2) {
    [locale, country] = localeCountry.split('-');
  } else {
    locale = localeCountry;
    country = localeCountry;
  }
  return { locale, country };
};

const redirectProfeminaOrigin = (localeCountry) => {
  const { locale, country } = splitLocaleCountry(localeCountry);
  const url = routes[locale].countries[country];
  redirect(url);
};

const redirectProfeminaTestOrigin = (locale, testId) => {
  const { referrer } = document;
  const url = referrer === pfUrl ? routes[locale].pages[testId] : pfUrl;
  redirect(url);
};

const redirectProfeminaContactForm = (localeCountry) => {
  const { locale } = splitLocaleCountry(localeCountry);
  const url = routes[locale].pages.contact;
  redirect(url);
};

const redirectProfeminaTest = (localeCountry, testId) => {
  const { locale, country } = splitLocaleCountry(localeCountry);
  if (Object.keys(testRedirects).includes(locale)) {
    const rerouteInfo = testRedirects[locale];

    let nextCountry = rerouteInfo.defaultCountry;
    if (rerouteInfo.countries.includes(country)) {
      nextCountry = country;
    }
    const nextLocaleCountry = `${locale}-${nextCountry}`;

    const path = rerouteInfo.path[testId];

    const rerouteUrl = `${pfUrl}/${nextLocaleCountry}${path}`;

    redirect(rerouteUrl);
    return true;
  }
  return false;
};

// close window if opened with target="_blank" attribute
// window.close();

export {
  redirect,
  redirectProfeminaOrigin,
  redirectProfeminaTestOrigin,
  redirectProfeminaContactForm,
  redirectProfeminaTest,
};
