/* eslint-disable no-shadow */
import { createRatingInfo } from '@/api/rating';
import { getField, updateField } from 'vuex-map-fields';
import { now } from '@/utils/time';

function defaultState() {
  return {
    stars: null,
    text: '',
  };
}

const state = defaultState();

const getters = {
  getField,
  stars: (state) => state.stars,
};

const mutations = {
  updateField,
  reset(state) {
    Object.assign(state, defaultState());
  },
  createStarRating(state, payload) {
    state.stars = {
      ...state.stars,
      ...payload,
    };
  },
};

const actions = {
  reset({ commit }) {
    commit('reset');
  },
  createStarRating({ commit }, payload) {
    const { questionKey, rating } = payload;
    const { sessionId } = this.state.testMicro;
    const data = {
      uid: sessionId,
      timestamp: now(),
      stars: rating,
    };
    const questionData = { [questionKey]: data };
    commit('createStarRating', questionData);
  },
  async postRatingData({ state }) {
    try {
      if (state.stars) {
        const ratingArr = Array.from(Object.values(state.stars));
        ratingArr.forEach(async (data) => {
            await createRatingInfo({ ...data, ...{ comment: state.text } });
        });
      }
    } catch (error) {
      throw new Error(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
