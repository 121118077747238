import { authCredentials as auth, apiUrl } from '@/api/apiConfig';
import axios from 'axios';

const getIpInfo = async (ipAddress) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${apiUrl}/ip/${ipAddress}`,
      headers: auth,
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

const checkIpInfo = async (ipAddress) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${apiUrl}/ip/check/${ipAddress}`,
      headers: auth,
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

const createIpInfo = async (data) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${apiUrl}/ip/`,
      data: { input: data },
      headers: auth,
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export {
  getIpInfo,
  checkIpInfo,
  createIpInfo,
};
