import { authCredentials as auth, apiUrl } from '@/api/apiConfig';
import axios from 'axios';

const createEmailInfo = async (data) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${apiUrl}/email/`,
      data: { input: data },
      headers: auth,
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export {
  createEmailInfo,
};
