/* eslint-disable no-shadow */
import { checkIpInfo, createIpInfo } from '@/api/ip';
import { createSHA256 } from '@/utils/hash';
import { include } from '@/utils/json';
import { now } from '@/utils/time';

function defaultState() {
  return {
    address: '',
    addressHash: '',
    location: {},
  };
}

const state = defaultState();

const getters = {
  address: (state) => state.address,
  addressHash: (state) => state.addressHash,
  location: (state) => state.location,
};

const mutations = {
  reset(state) {
    Object.assign(state, defaultState());
  },
  setAddress(state, ip) {
    state.address = ip;
    state.addressHash = createSHA256(ip);
  },
  setLocation(state, ipInfo) {
    state.location = {
      ip_address: state.addressHash,
      timestamp: now(),
      ...ipInfo,
    };
  },
};

const actions = {
  async fetch({ commit, dispatch }) {
    try {
      const r = await fetch('https://api.ipify.org?format=json');
      const { ip } = await r.json();
      commit('setAddress', ip);
      dispatch('fetchInfo');
    } catch (error) {
      throw new Error(error);
    }
  },
  async fetchInfo({ commit, dispatch, state }) {
    try {
      // check whether IP Data in last 24h already exist in DB
      const response = await checkIpInfo(state.addressHash);
      if (!response.data) {
        const r = await fetch(`https://ipwhois.app/json/${state.address}`);
        const json = await r.json();
        const data = include(json, [
          'continent',
          'country',
          'country_code',
          'region',
          'city',
          'latitude',
          'longitude',
          'isp',
        ]);
        commit('setLocation', data);
        dispatch('postInfo');
      }
    } catch (error) {
      throw new Error(error);
    }
  },
  async postInfo({ state }) {
    try {
      const data = state.location;
      await createIpInfo(data);
    } catch (error) {
      throw new Error(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
