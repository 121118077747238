import store from '@/store';

export default {
  error(message) {
    store.dispatch('notification/notify', {
      type: 'error',
      message,
    });
  },

  success(message) {
    store.dispatch('notification/notify', {
      type: 'success',
      message,
    });
  },

  info(message) {
    store.dispatch('notification/notify', {
      type: 'info',
      message,
    });
  },
};
