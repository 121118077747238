const finalQuestion = 'Was mir jetzt am meisten helfen würde:';

const finalChoicesGeneral = {
  0: 'jemand, der mir zuhört und für mich da ist',
  1: 'Zeit für mich',
  2: 'ein Plan',
  3: 'dass mein Partner „Ja“ zum Kind sagt',
  4: 'konkrete praktische oder finanzielle Unterstützung',
};

// Pfad 1: Er will das Kind nicht
// Pfad 8.5: Vergewaltigung
// Path 1: He doesn't want the child
// Path 8.5: Rape
const finalChoicesException = {
  0: 'jemand, der mir zuhört und für mich da ist',
  1: 'Zeit für mich',
  2: 'ein Plan',
  3: 'konkrete praktische oder finanzielle Unterstützung',
};

const adviceSomebodyWhoListensToMe = '<p><strong>Wie kann es weitergehen?</strong><br>Vielleicht gibt es <strong>einen lieben Menschen in Deinem Umfeld</strong>, dem Du vertraust und mit dem Du sprechen könntest? Eine Person, bei der Du Dich aufgehoben fühlst?<br><br>Wie Du sicherlich ahnst, kann Dir letztlich niemand diese Entscheidung abnehmen. Und doch können Menschen einander unterstützen und begleiten. Ich hoffe, ich konnte Dir durch die digitale Beratung bereits ein wenig Halt geben. Selbstverständlich kannst Du auch in weiterführenden, persönlichen Kontakt mit uns <i>profemina</i>-Beraterinnen treten: <a href="https://www.profemina.org/de-de/ueber-uns/kontaktformular" target="_blank" style="color: #55855d">Kontaktformular</a></p>';
const adviceTimeForMyself = '<p><strong>Wie kann es weitergehen?</strong><br>Die Zeit, die Du Dir wünschst, darfst Du Dir ganz bewusst schenken, um Dir etwas Gutes zu tun und Dich selbst innerlich zu sortieren.<br><br>Wie Du sicherlich ahnst, kann Dir letztlich niemand diese Entscheidung abnehmen. Und doch können Menschen einander unterstützen und begleiten. Wir hoffen, diese Digital-Beratung kann Dir bereits ein wenig Halt geben. Selbstverständlich kannst Du auch in weiterführenden, persönlichen Kontakt mit uns <i>profemina</i>-Beraterinnen treten: <a href="https://www.profemina.org/de-de/ueber-uns/kontaktformular" target="_blank" style="color: #55855d">Kontaktformular</a></p>';
const advicePlan = '<p><strong>Wie kann es weitergehen?</strong><br>Manchmal hilft es, die Gedanken und Gefühle zu Papier zu bringen, um sich zu sortieren. Du kannst versuchen, einfach einen kleinen Schritt nach dem anderen zu setzen – Du musst nicht alles auf einmal schaffen. Es kann sein, dass sich Dir nach jedem Schritt eine neue Perspektive eröffnet, von der aus Du weiter denken kannst.<br><br>Wie Du sicherlich ahnst, kann Dir letztlich niemand diese Entscheidung abnehmen. Und doch können Menschen einander unterstützen und begleiten. Ich hoffe, ich konnte Dir durch die digitale Beratung bereits ein wenig Halt geben. Selbstverständlich kannst Du auch in weiterführenden, persönlichen Kontakt mit uns <i>profemina</i>-Beraterinnen treten: <a href="https://www.profemina.org/de-de/ueber-uns/kontaktformular" target="_blank" style="color: #55855d">Kontaktformular</a></p>';
const adviceSupport = '<p><strong>Wie kann es weitergehen?</strong><br>Viele Frauen in Deiner Situation suchen konkrete praktische oder finanzielle Unterstützung. Daher gibt es sowohl staatliche als auch private Förderungen. Einiges davon findest Du auf unserer Homepage unter "Finanzielles" und "Beratung & Hilfe". Vielleicht gibt es außerdem auch Menschen in Deinem Umfeld, die für Dich da sein können?<br><br>Wie Du sicherlich ahnst, kann Dir letztlich niemand diese Entscheidung abnehmen. Und doch können Menschen einander unterstützen und begleiten. Ich hoffe, ich konnte Dir durch die digitale Beratung bereits ein wenig Halt geben. Selbstverständlich kannst Du auch in weiterführenden, persönlichen Kontakt mit uns <i>profemina</i>-Beraterinnen treten: <a href="https://www.profemina.org/de-de/ueber-uns/kontaktformular" target="_blank" style="color: #55855d">Kontaktformular</a></p>';

const advicePartnerSaysYesGeneral = '<p><strong>Wie kann es weitergehen?</strong><br>Es ist verständlich und natürlich, dass Du Dir nun Unterstützung von Deinem Partner wünschen würdest!<br><br>Erfahrungsgemäß fällt es sehr vielen Männern zunächst schwer, sich auf eine Schwangerschaft einzulassen. Im ersten Moment sind oft Sorgen und Zweifel im Vordergrund. Vermutlich braucht der werdende Vater mehr Zeit als Du, um mit Herz und Verstand die neue Situation anzunehmen. Vielleicht klingt es für Dich im Augenblick weit hergeholt, doch die allermeisten Männer stehen schließlich doch zu ihrer Verantwortung und werden sogar zu stolzen Vätern. Womöglich hilft es Euch beiden, wenn Du gerade jetzt besonders auf Dich selbst und Dein Inneres hörst und die für Dich passende Entscheidung suchst.<br><br>Wie Du sicherlich ahnst, kann Dir letztlich niemand diese Entscheidung abnehmen. Und doch können Menschen einander unterstützen und begleiten. Ich hoffe, ich konnte Dir durch die digitale Beratung bereits ein wenig Halt geben. Selbstverständlich kannst Du auch in weiterführenden, persönlichen Kontakt mit uns <i>profemina</i>-Beraterinnen treten: <a href="https://www.profemina.org/de-de/ueber-uns/kontaktformular" target="_blank" style="color: #55855d">Kontaktformular</a></p>';
// Spezialfall für Pfad 2: es zwischen uns kompliziert ist
// Special Case for Path 2: our relationship is complicated
const advicePartnerSaysYesTree211 = '<p><strong>Wie kann es weitergehen?</strong><br>Du hast angegeben, dass es Dir im Moment am meisten helfen würde, wenn Dein (Ex-)Partner Ja sagen würde zum Kind. Es ist verständlich und natürlich, dass Du Dir das wünschst. Und die überwiegende Mehrheit der Frauen in Deiner Situation fühlen genau so!<br><br>Erfahrungsgemäß fällt es sehr vielen Männern zunächst schwer, sich auf eine Schwangerschaft einzulassen, gerade auch, wenn die Beziehung kompliziert ist. Im ersten Moment sind oft Sorgen und Zweifel im Vordergrund. Vermutlich braucht der werdende Vater mehr Zeit als Du, um mit Herz und Verstand die neue Situation anzunehmen. Vielleicht klingt es für Dich im Augenblick weit hergeholt, doch die allermeisten Männer stehen schließlich doch zu ihrer Verantwortung und werden sogar zu stolzen Vätern. Womöglich hilft es Euch beiden, wenn Du gerade jetzt besonders auf Dich selbst und Dein Inneres hörst und die für Dich passende Entscheidung suchst.<br><br>Wie Du sicherlich ahnst, kann Dir niemand diese Entscheidung abnehmen. Und doch können Menschen einander unterstützen und begleiten. Ich hoffe, ich konnte Dir durch die digitale Beratung bereits ein wenig Halt geben. Selbstverständlich kannst Du auch in weiterführenden, persönlichen Kontakt mit uns <i>profemina</i>-Beraterinnen treten: <a href="https://www.profemina.org/de-de/ueber-uns/kontaktformular" target="_blank" style="color: #55855d">Kontaktformular</a></p>';
const advicePartnerSaysYesTree212 = '<p><strong>Wie kann es weitergehen?</strong><br>Du hast angegeben, dass es Dir im Moment am meisten helfen würde, wenn Dein (Ex-)Partner Ja sagen würde zum Kind. Es ist verständlich und natürlich, dass Du Dir das wünschst. Und die überwiegende Mehrheit der Frauen in Deiner Situation fühlen genau so!<br><br>Erfahrungsgemäß fällt es sehr vielen Männern zunächst schwer, sich auf eine Schwangerschaft einzulassen, gerade auch, wenn die Beziehung kompliziert ist. Im ersten Moment sind oft Sorgen und Zweifel im Vordergrund. Vermutlich braucht der werdende Vater mehr Zeit als Du, um mit Herz und Verstand die neue Situation anzunehmen. Vielleicht klingt es für Dich im Augenblick weit hergeholt, doch die allermeisten Männer stehen schließlich doch zu ihrer Verantwortung und werden sogar zu stolzen Vätern. Womöglich hilft es Euch beiden, wenn Du gerade jetzt besonders auf Dich selbst und Dein Inneres hörst und die für Dich passende Entscheidung suchst.<br><br>Wie Du sicherlich ahnst, kann Dir niemand diese Entscheidung abnehmen. Und doch können Menschen einander unterstützen und begleiten. Selbstverständlich kannst Du auch in weiterführenden, persönlichen Kontakt mit uns <i>profemina</i>-Beraterinnen treten: <a href="https://www.profemina.org/de-de/ueber-uns/kontaktformular" target="_blank" style="color: #55855d">Kontaktformular</a></p>';
// Path 2.3.1 & 2.3.2
// das Kind ist von einem anderen
// the child is from someone else
const advicePartnerSaysYesTree23 = '<p><strong>Wie kann es weitergehen?</strong><br>Du hast angegeben, dass es Dir im Moment am meisten helfen würde, wenn Dein Partner „ja“ sagen würde zum Kind. Es ist verständlich und natürlich, dass Du Dir das wünschst! Erfahrungsgemäß fällt es sehr vielen Männer zunächst schwer, sich auf eine Schwangerschaft einzulassen. Umso mehr, wenn das Kind von einem anderen ist. Im ersten Moment sind wohl Sorgen, Enttäuschung und Zweifel im Vordergrund. Vermutlich braucht er Zeit, um sich mit Herz und Verstand der neuen Situation zu stellen. Womöglich hilft es Euch beiden, wenn Du gerade jetzt besonders auf Dich selbst und Dein Inneres hörst und die für Dich passende Entscheidung suchst. Das gibt ihm den nötigen Freiraum, seinen eigenen Kurs zu finden und vielleicht schließlich doch zu dem Kind zu stehen – das kommt immer wieder vor! Wie Du sicherlich ahnst, kann Dir niemand diese Entscheidung abnehmen. Und doch können Menschen einander unterstützen und begleiten. Selbstverständlich kannst Du auch in weiterführenden, persönlichen Kontakt mit uns <i>profemina</i>-Beraterinnen treten: <a href="https://www.profemina.org/de-de/ueber-uns/kontaktformular" target="_blank" style="color: #55855d">Kontaktformular</a></p>';

// Text Components / Duplications
const adviceTextBothWays = '<br><br><strong>Beide Wege anschauen</strong><br>Momentan fragst Du Dich: Abtreiben – ja oder nein? Wie auch immer Du entscheidest: Beides hat Auswirkungen auf Dein Leben. Vielleicht kann es Dir helfen, Dir selbst einige Fragen zu beantworten, um den Blick zu weiten und einer gereiften Entscheidung näher zu kommen.<br>';
const adviceTextBothWaysIntro = 'Stell Dir vor, Du hättest Deine Entscheidung bereits getroffen und würdest im Rückblick sagen: Es war eine wirklich gute Entscheidung!';

const adviceTextTree1BiggestConcernsOption1 = '<strong>Deine größte Sorge</strong><br>Vermutlich bringt die große Nachricht einer Schwangerschaft an sich schon vieles in Dir durcheinander. Vielleicht hattest Du Dir das Schwanger–Sein ursprünglich sogar ziemlich anders vorgestellt. Wenn nun der Vater des Kindes sich auch noch kritisch äußert, kann Dich das verständlicherweise an Deine persönlichen Grenzen bringen';
const adviceTextTree1BiggestConcernsOption2 = '<strong>Deine größte Sorge</strong><br>Vermutlich bringt die große Nachricht einer Schwangerschaft an sich schon vieles in Dir durcheinander. Vielleicht hattest Du Dir das Schwanger–Sein ursprünglich sogar ziemlich anders vorgestellt. Wenn nun der Vater des Kindes sich auch noch kritisch äußert, kann das verständlicherweise Sorgen auslösen.';

const adviceTextTree21BiggestConcern = '<strong>Deine größte Sorge</strong><br>Eigentlich ist es schon aus zwischen Euch beiden – doch nun erwartest Du ein Kind von ihm. Einen ungünstigeren Zeitpunkt für eine Schwangerschaft scheint es auf den ersten Blick gar nicht zu geben. Wahrscheinlich bringt Dich das gerade an Deine persönlichen Grenzen…<br><br>Es liegt mit Sicherheit keine einfache Zeit hinter Dir, weil vielleicht schon über längere Zeit hinweg Spannungen die Beziehung geprägt haben. ';
const adviceTextTree22BiggestConcern = '<strong>Deine größte Sorge</strong><br>Es liegt mit Sicherheit keine einfache Zeit hinter Dir, weil womöglich schon über längere Zeit hinweg Spannungen die Partnerschaft geprägt haben. Das allein wirft schon viele Fragen auf.<br><br>Die zusätzliche Herausforderung der Schwangerschaft bringt Dich wahrscheinlich gerade an Deine persönlichen Grenzen. ';
const adviceTextTree23BiggestConcern = '<strong>Deine größte Sorge</strong><br>Vielleicht war es schon länger angespannt zwischen Dir und Deinem Partner. Schließlich hast Du womöglich bei einem anderen Mann das gesucht, was Du vermisst hast. Vielleicht lief es eigentlich aber auch ganz gut bei Euch – trotzdem kam es zu dieser Situation mit einem Anderen, die nun nicht mehr rückgängig gemacht werden kann. Wie auch immer es genau bei Dir war: Nun bist Du (vielleicht oder sicher) von einem anderen Mann schwanger.<br><br>Auf einen Schlag ist alles anders, und vermutlich bewegen Dich nun die verschiedensten Gedanken, Gefühle, Sorgen und Ängste. Da ist es sehr verständlich, dass diese Situation Dich gerade an Deine persönlichen Grenzen bringt';

const adviceTextTree3BiggestConcern = '<strong>Deine größte Sorge</strong><br>Es gibt viele verschiedene Gründe, warum ein Kind zum momentanen Zeitpunkt gerade unpassend erscheint. In der momentanen Situation schwanger geworden zu sein, hat Dich womöglich gerade deshalb in große Verwirrung gebracht, weil Du eben nicht „einfach so“ ein Kind bekommen wolltest.<br><br>Vielleicht ist es für Dich besonders schwer, weil Du Deinem Kind gerecht werden möchtest und ganz für es dasein willst, wenn es in Dein Leben tritt. Eigentlich macht das ja gerade das Herz einer Mutter aus: Dass sie das Beste für ihr Kind will. Deshalb ist es verständlich, wenn die jetzige Situation eine richtige Zerreißprobe für Dich ist!<br><br>';

const adviceTextBullet1 = '<li>Was gibt Dir generell in unsicheren Zeiten Kraft, wo kannst Du jetzt auftanken?</li>';
const adviceTextBullet2 = '<li>Wenn Du später einmal auf Deine Entscheidung zurückblickst und dabei weißt, sie war gut und richtig: Woran würdest Du merken, dass sie richtig war? Worauf wärest Du besonders stolz?</li>';
const adviceTextBullet3 = '<li>Woran würdest Du konkret merken, dass die Entscheidung gut war?</li><li>Worauf wärst Du besonders stolz?</li>';

export {
  finalQuestion,
  finalChoicesGeneral,
  finalChoicesException,
  adviceSomebodyWhoListensToMe,
  adviceTimeForMyself,
  advicePlan,
  adviceSupport,
  advicePartnerSaysYesGeneral,
  advicePartnerSaysYesTree211,
  advicePartnerSaysYesTree212,
  advicePartnerSaysYesTree23,
  adviceTextBothWays,
  adviceTextBothWaysIntro,
  adviceTextTree1BiggestConcernsOption1,
  adviceTextTree1BiggestConcernsOption2,
  adviceTextTree21BiggestConcern,
  adviceTextTree22BiggestConcern,
  adviceTextTree23BiggestConcern,
  adviceTextTree3BiggestConcern,
  adviceTextBullet1,
  adviceTextBullet2,
  adviceTextBullet3,
};
