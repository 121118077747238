import { range } from '@/utils';
import { fixedValue } from '@/utils/json';

const getFinalQuestionObject = (length, value) => {
  const r = range(0, length - 1);
  const data = fixedValue(r, value);
  return data;
};

export {
  getFinalQuestionObject,
};
