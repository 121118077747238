export default {
  light: {
    primary: '#55855D',
    secondary: '#FFFFFF',
    tertiary: '#F8D37D',
    quaternary: '#000000',
    backgroundCard: '#F5F5F5',
    close: '#777575',
    closeDialog: '#BC2F2F',
    border: '#9F9F9F',
    line: '#C1C1C1',
    error: '#C4252B',
    info: '#2672D3',
    success: '#41B01E',
    warning: '#E39828',
    background: '#F8F4EE',
  },
};
