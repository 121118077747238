import i18n from '@/plugins/i18n';

const i18nUtils = {
  get defaultLocale() {
    return process.env.VUE_APP_I18N_LOCALE;
  },
  get supportedLocales() {
    return process.env.VUE_APP_I18N_SUPPORTED_LOCALES.split(',');
  },
  get loadedLocales() {
    return i18n.availableLocales;
  },
  get currentLocale() {
    return i18n.locale;
  },
  set currentLocale(locale) {
    i18n.locale = locale;
  },
  getUserSupportedLocale() {
    const userPreferredLocale = i18nUtils.getUserLocale();

    // Check if user preferred browser locale is supported
    if (i18nUtils.isLocaleSupported(userPreferredLocale.locale)) {
      return userPreferredLocale.locale;
    }
    // Check if user preferred locale without the ISO is supported
    if (i18nUtils.isLocaleSupported(userPreferredLocale.localeNoISO)) {
      return userPreferredLocale.localeNoISO;
    }
    return i18nUtils.defaultLocale;
  },
  getUserLocale() {
    const locale = window.navigator.language
    || window.navigator.userLanguage || i18nUtils.defaultLocale;
    return {
      locale,
      localeNoISO: locale.split('-')[0],
    };
  },
  // Sets the locale to various services (the html tag etc)
  setI18nLocaleInServices(locale) {
    i18nUtils.currentLocale = locale;
    document.querySelector('html').setAttribute('lang', locale);
    return locale;
  },
  /**
   * Loads new translation messages and changes the locale when finished
   * @param locale
   * @return {Promise<any>}
   */
  loadLocale(locale) {
    // If the locale not supported
    if (!i18nUtils.isLocaleSupported(locale)) {
      return Promise.reject(new Error('Locale not supported'));
    }
    // If the same locale
    if (i18n.locale === locale) {
      return Promise.resolve(locale);
    }
    // If the locale was already loaded
    if (i18nUtils.loadedLocales.includes(locale)) {
      return Promise.resolve(i18nUtils.setI18nLocaleInServices(locale));
    }
    return i18nUtils.loadLocaleFile(locale).then((msgs) => {
      i18n.setLocaleMessage(locale, msgs.default || msgs);
      i18nUtils.setI18nLocaleInServices(locale);
      return Promise.resolve(i18nUtils.setI18nLocaleInServices(locale));
    });
  },
  /**
   * Async loads a translation file
   * @param locale
   * @return {Promise<*>|*}
   */
  loadLocaleFile(locale) {
    return import(/* webpackChunkName: "locales-[request]" */ `@/locales/${locale}/messages.js`);
  },
  isLocaleSupported(locale) {
    return i18nUtils.supportedLocales.includes(locale);
  },
  /**
   * Returns a new route object that has the current locale already defined
   * To be used on pages and components, outside of the main \ route, like on Headers and Footers.
   * @example <router-link :to="$i18nRoute({ name: 'someRoute'})">Click Me </router-link>
   * @param {Object} to - route object to construct
   */
  i18nRoute(to) {
    return {
      ...to,
      params: { locale: this.currentLocale, ...to.params },
    };
  },
};

export { i18nUtils };
