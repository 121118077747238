module.exports = function markup(content) {
  const mailBody = `<!doctype html>
  <html>
    <head>
      <meta name="viewport" content="width=device-width">
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
      <title>Profemina e.V.</title>
      <style>
        body {
          font-family: sans-serif;
          -webkit-font-smoothing: antialiased;
          font-size: 16px;
          line-height: 1.4;
          margin: 0;
          padding: 12px;
          max-width: 800px;
          -ms-text-size-adjust: 100%;
          -webkit-text-size-adjust: 100%;
        }
        p.contact {
          margin-top: 12px !important;
          margin-bottom: 12px !important;
        }
        p.footer {
          font-family: Century Gothic, Arial, Helvetica, sans-serif !important;
          font-size: 10px !important;
          color: #999999 !important;
        }
      </style>
    </head>
    <body>
      <p>${content.cta}</p>
      <p>${content.greeting}</p>
      <p>${content.message}</p>
      <p>${content.htmlResult}</p>
      <p>${content.signatureGreeting}</p>
      <p>${content.signature}</p>
      <p>${content.cta}</p>
      <br>
      <br>
      <p class="contact">${content.contact}</p>
      <hr/>
      <p class="footer">${content.footer}</p>
    </body>
  </html>
  `;
  return mailBody;
};
