<template>
  <v-app>
    <TopBar v-if="view"/>
    <v-main :style="style">
      <Dash v-if="view"/>
      <v-container fluid>
        <v-scroll-x-transition :hide-on-leave="true">
          <router-view />
        </v-scroll-x-transition>
      </v-container>
      <ProgressLinear v-if="testView" />
    </v-main>
    <LoadingResult />
    <Notification />
    <RefreshApp />
    <Offline />
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import lazyLoad from '@/utils/lazyLoad';

export default {
  name: 'App',
  components: {
    TopBar: lazyLoad('components/Layout/TopBar'),
    Dash: lazyLoad('components/Layout/Dash'),
    ProgressLinear: lazyLoad('components/Layout/ProgressLinear'),
    LoadingResult: lazyLoad('components/LoadingResult'),
    Notification: lazyLoad('components/Notification'),
    RefreshApp: lazyLoad('components/RefreshApp'),
    Offline: lazyLoad('components/Offline'),
  },
  created() {
    // supress browser's default prompt
    window.addEventListener('beforeinstallprompt', (event) => {
      event.preventDefault();
    });
    this.$store.dispatch('ip/fetch');
  },
  computed: {
    ...mapGetters('app', [
      'view',
      'testView',
      'resultView',
      'notFoundView',
      'deploymentMode',
    ]),
    style() {
      if (this.notFoundView) {
        return `background: url(${process.env.VUE_APP_URL_CDN}/images/404.jpg)`;
      }
      return '';
    },
  },
};
</script>

<style lang="scss">
/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none !important;
  width: 0px !important;
  background: transparent !important;
}

/* Hide scrollbar for IE, Edge add Firefox */
body {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important; /* Firefox */
}
</style>
