import { createHash } from 'crypto';

const createSHA256 = (message) => {
  const hash = createHash('sha256').update(message).digest('hex');
  return hash;
};

export {
  createSHA256,
};
