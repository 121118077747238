export default {
  de: {
    defaultCountry: 'de',
    countries: ['de', 'at', 'ch', 'it'],
    path: {
      situation: '/ungeplant-schwanger/erste-hilfe',
      problems: '/abtreibung/abtreibungstest',
      resources: '/ungeplant-schwanger/was-sind-meine-staerken',
      ressources: '/ungeplant-schwanger/was-sind-meine-staerken',
    },
  },
  it: {
    defaultCountry: 'it',
    countries: ['it'],
    path: {
      situation: '/gravidanza-indesiderata/pronto-soccorso',
      problems: '/aborto/test-daborto',
      resources: '/gravidanza-indesiderata/quali-sono-mie-capacita',
      ressources: '/gravidanza-indesiderata/quali-sono-mie-capacita',
    },
  },
  en: {
    defaultCountry: 'us',
    countries: ['us', 'gb'],
    path: {
      situation: '/unintended-pregnancy/where-to-start',
      problems: '/abortion/abortion-test',
      resources: '/unintended-pregnancy/what-are-my-strengths',
      ressources: '/unintended-pregnancy/what-are-my-strengths',
    },
  },
  pt: {
    defaultCountry: 'pt',
    countries: ['pt'],
    path: {
      situation: '/gravidez-nao-planeada/primeiros-socorros',
      problems: '/aborto/teste-do-aborto',
      resources: '/gravidez-nao-planeada/quais-os-meus-pontos-fortes',
      ressources: '/gravidez-nao-planeada/quais-os-meus-pontos-fortes',
    },
  },
};
