const authCredentials = {
  'X-Api-Key': process.env.VUE_APP_AWS_API_GATEWAY_API_KEY,
};

const apiUrl = process.env.VUE_APP_AWS_API_GATEWAY_ENDPOINT;

export {
  authCredentials,
  apiUrl,
};
