/* eslint-disable no-shadow */
function defaultState() {
  return {
    all: ['situation', 'problems', 'resources', 'ressources'],
    additives: ['situation', 'resources', 'ressources'],
    pathDependents: ['problems'],
    textChoiceSelection: {
      situation: [1, 2, 4, 5],
      problems: [],
      resources: [],
      ressources: [],
    },
    completed: [],
    completedChoiceJourney: {},
  };
}

const state = defaultState();

const getters = {
  all: (state) => state.all,
  availableTests: (state) => state.all.filter((e) => e !== 'ressources'),
  additive: (state, getters, rootState) => state.additives
    .includes(rootState.testMicro.id),
  pathDependent: (state, getters, rootState) => state.pathDependents
    .includes(rootState.testMicro.id),
  textChoiceSelection: (state) => state.textChoiceSelection,
  remaining: (state) => state.all.filter((e) => !state.completed.includes(e)),
  done: (state) => state.all.every((e) => state.completed.includes(e)),
};

const mutations = {
  reset(state) {
    Object.assign(state, defaultState());
  },
  completed(state) {
    const { id } = this.state.testMicro;
    state.completed.push(id);
  },
  completedChoiceJourney(state) {
    const { id, choiceJourney } = this.state.testMicro;
    Object.assign(state.completedChoiceJourney, { [id]: choiceJourney });
  },
};

const actions = {
  reset({ commit }) {
    commit('reset');
  },
  completed({ commit }) {
    commit('completed');
  },
  completedChoiceJourney({ commit }) {
    commit('completedChoiceJourney');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
