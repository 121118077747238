const pfUrl = 'https://www.profemina.org';

export default {
  de: {
    countries: {
      de: `${pfUrl}/de-de`,
      at: `${pfUrl}/de-at`,
      ch: `${pfUrl}/de-ch`,
    },
    pages: {
      situation: `${pfUrl}/ungeplant-schwanger/erste-hilfe`,
      resources: `${pfUrl}/abtreibung/soll-ich-abtreiben`,
      ressources: `${pfUrl}/abtreibung/soll-ich-abtreiben`,
      problems: `${pfUrl}/ungeplant-schwanger/was-sind-meine-staerken`,
      contact: `${pfUrl}/de-de/ueber-uns/kontaktformular`,
    },
  },
  en: {
    countries: {
      en: `${pfUrl}/en`,
      gb: `${pfUrl}/en-us`,
      us: `${pfUrl}/en-gb`,
    },
    pages: {
      situation: `${pfUrl}/ungeplant-schwanger/erste-hilfe`,
      resources: `${pfUrl}/abtreibung/soll-ich-abtreiben`,
      ressources: `${pfUrl}/abtreibung/soll-ich-abtreiben`,
      problems: `${pfUrl}/ungeplant-schwanger/was-sind-meine-staerken`,
      contact: `${pfUrl}/de-de/ueber-uns/kontaktformular`,
    },
  },
  it: {
    countries: {
      it: `${pfUrl}/it-it`,
      de: `${pfUrl}/it-de`,
    },
    pages: {
      situation: `${pfUrl}/ungeplant-schwanger/erste-hilfe`,
      resources: `${pfUrl}/abtreibung/soll-ich-abtreiben`,
      ressources: `${pfUrl}/abtreibung/soll-ich-abtreiben`,
      problems: `${pfUrl}/ungeplant-schwanger/was-sind-meine-staerken`,
      contact: `${pfUrl}/de-de/ueber-uns/kontaktformular`,
    },
  },
  pt: {
    countries: {
      pt: `${pfUrl}/pt-pt`,
    },
    pages: {
      situation: `${pfUrl}/ungeplant-schwanger/erste-hilfe`,
      resources: `${pfUrl}/abtreibung/soll-ich-abtreiben`,
      ressources: `${pfUrl}/abtreibung/soll-ich-abtreiben`,
      problems: `${pfUrl}/ungeplant-schwanger/was-sind-meine-staerken`,
      contact: `${pfUrl}/pt-pt/sobre-nos/formulario-contacto`,
    },
  },
};
