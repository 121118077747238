// keep the keys that are provided
const include = (obj, keys) => (
  keys.reduce((a, c) => ({ ...a, [c]: obj[c] }), {})
);

// remove all keys except the ones provided
const exclude = (obj, keys) => {
  const keysToKeep = Object.keys(obj).filter((elt) => !keys.includes(elt));
  return include(keysToKeep, obj);
};

const fixedValue = (keys, value) => (keys.reduce((o, k) => ({ ...o, [k]: value }), {}));

export {
  include,
  exclude,
  fixedValue,
};
