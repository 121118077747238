/* eslint-disable no-shadow */
import i18n from '@/plugins/i18n';
import { now } from '@/utils/time';
import { v4 as uuidv4 } from 'uuid';

function defaultState() {
  return {
    id: null,
    sessionId: null,
    activeStep: 0,
    formSteps: {},
    length: null,
    selected: false,
    selectedIndex: null,
    choiceJourney: [],
    timestamps: {
      startQuestion: [],
      choice: [],
      endAdvice: [],
    },
    result: null,
  };
}

const state = defaultState();

const getters = {
  id: (state) => state.id,
  activeStep: (state) => state.activeStep,
  formSteps: (state) => state.formSteps,
  step: (state) => state.formSteps[state.activeStep],
  length: (state) => state.length,
  selected: (state) => state.selected,
  selectedIndex: (state) => state.selectedIndex,
  firstStep: (state) => state.activeStep === 0,
  lastStep: (state, getters) => state.activeStep + 1 === getters.length,
  choiceJourney: (state) => state.choiceJourney,
  result: (state) => state.result,
};

const mutations = {
  reset(state) {
    Object.assign(state, defaultState());
  },
  initiateTest(state, id) {
    state.id = id;
    state.sessionId = uuidv4();
    state.formSteps = i18n.t(`${state.id}.formSteps`);
    state.timestamps.startQuestion = [now()];
    state.length = Object.keys(state.formSteps).length;
  },
  resetLength(state) {
    state.length = Object.keys(state.formSteps).length;
  },
  setLength(state, payload) {
    state.length = payload;
  },
  selected(state, payload) {
    state.selected = payload;
  },
  selectedIndex(state, payload) {
    state.selectedIndex = payload;
  },
  previousStep(state) {
    if (state.activeStep > 0) state.activeStep -= 1;
  },
  nextStep(state, length) {
    if (state.activeStep !== length) state.activeStep += 1;
  },
  saveChoice(state) {
    state.choiceJourney.push(state.selectedIndex);
  },
  saveTimestamp(state, key) {
    state.timestamps[key].push(now());
  },
  removeChoice(state) {
    state.choiceJourney = state.choiceJourney.slice(0, -1);
  },
  removeTimestamp(state, key) {
    state.timestamps[key] = state.timestamps[key].slice(0, -1);
  },
  setResult(state, payload) {
    state.result = payload;
  },
};

const actions = {
  reset({ commit }) {
    commit('reset');
  },
  initiateTest({ commit }, payload) {
    commit('reset');
    commit('initiateTest', payload);
  },
  resetLength({ commit }) {
    commit('resetLength');
  },
  resetSelection({ commit }) {
    commit('selected', false);
    commit('selectedIndex', null);
  },
  setLength({ commit }, payload) {
    commit('setLength', payload);
  },
  setSelection({ commit }, payload) {
    commit('selected', true);
    commit('selectedIndex', payload);
  },
  previousStep({ commit, dispatch }) {
    dispatch('resetSelection');
    commit('previousStep');
  },
  nextStep({ commit, dispatch, getters }) {
    dispatch('resetSelection');
    commit('nextStep', getters.length);
  },
  saveChoice({ commit }) {
    commit('saveChoice');
  },
  saveTimestamp({ commit }, payload) {
    commit('saveTimestamp', payload);
  },
  removeChoice({ commit }) {
    commit('removeChoice');
  },
  removeTimestamp({ commit }, payload) {
    commit('removeTimestamp', payload);
  },
  setResult({ commit }, payload) {
    commit('setResult', payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
