/* eslint-disable no-shadow */
function defaultState() {
  return {
    domain: window.location.origin,
    deploymentMode: process.env.VUE_APP_MODE,
    pwaMode: false,
    localeCountry: null,
    view: '',
    loading: false,
  };
}

const state = defaultState();

const getters = {
  localhost: (state) => state.domain.includes('localhost'),
  deploymentMode: (state) => state.deploymentMode,
  productionMode: (state) => state.deploymentMode === 'production',
  stagingMode: (state) => state.deploymentMode === 'staging',
  developmentMode: (state) => state.deploymentMode === 'development',
  pwaMode: (state) => state.pwaMode,
  localeCountry: (state) => state.localeCountry,
  view: (state) => state.view,
  homeView: (state) => state.view === 'Home',
  testView: (state) => state.view === 'Test',
  resultView: (state) => state.view === 'Result',
  doneView: (state) => state.view === 'Done',
  notFoundView: (state) => state.view === 'NotFound',
  loading: (state) => state.loading,
};

const mutations = {
  reset(state) {
    Object.assign(state, defaultState());
  },
  setPwaMode(state, payload) {
    state.pwaMode = payload;
  },
  localeCountry(state, payload) {
    state.localeCountry = payload;
  },
  view(state, payload) {
    state.view = payload;
  },
  loading(state, payload) {
    state.loading = payload;
  },
};

const actions = {
  setPwaMode({ commit }, payload) {
    commit('setPwaMode', payload);
  },
  localeCountry({ commit }, payload) {
    commit('localeCountry', payload);
  },
  view({ commit }, payload) {
    commit('view', payload);
  },
  async load({ commit }) {
    commit('loading', true);
    await new Promise((resolve) => setTimeout(resolve, 3000));
    commit('loading', false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
