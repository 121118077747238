import { SESClient, SendEmailCommand } from '@aws-sdk/client-ses';

const markupTestReporting = require('./markups/testReporting');
const markupRatingReporting = require('./markups/ratingReporting');
const markupUserMail = require('./markups/userMail');

const production = process.env.VUE_APP_MODE === 'production';

const EMAIL = {
  SOURCE: process.env.VUE_APP_EMAIL_GLOBAL_SENDER,
  RECIPIENT_RATING_REPORTING: production ? process.env.VUE_APP_EMAIL_RECIPIENT_RATING_REPORTING : 'alexander.schaetz@web.de',
  RECIPIENT_TEST_REPORTING: production ? process.env.VUE_APP_EMAIL_RECIPIENT_TEST_REPORTING : 'alexander.schaetz@web.de',
};

const SESConfig = {
  apiVersion: '2010-12-01',
  credentials: {
    accessKeyId: process.env.VUE_APP_AWS_SES_ACCESS_KEY_ID,
    secretAccessKey: process.env.VUE_APP_AWS_SES_SECRET_ACCESS_KEY,
  },
  region: process.env.VUE_APP_AWS_SES_REGION,
};

const client = new SESClient(SESConfig);

const send = async (message) => {
  try {
    const command = new SendEmailCommand(message);
    await client.send(command);
  } catch (error) {
    throw new Error(error);
  }
};

const sendUserResult = async (content) => {
  const message = {
    Source: `profemina<${EMAIL.SOURCE}>`,
    Destination: {
      ToAddresses: [content.toEmail],
    },
    Message: {
      Subject: { Data: `${content.subject}` },
      Body: {
        Html: {
          Data: markupUserMail(content),
        },
      },
    },
  };
  await send(message);
};

const reportTest = async (content) => {
  const message = {
    Source: `Elea<${EMAIL.SOURCE}>`,
    Destination: {
      ToAddresses: [EMAIL.RECIPIENT_TEST_REPORTING],
    },
    Message: {
      Subject: { Data: `${content.localeCountry} | Ein neuer "${content.testId}" ist eingetroffen` },
      Body: {
        Html: {
          Data: markupTestReporting(content),
        },
      },
    },
  };
  await send(message);
};

const reportRating = async (content) => {
  const message = {
    Source: `Elea<${EMAIL.SOURCE}>`,
    Destination: {
      ToAddresses: [EMAIL.RECIPIENT_RATING_REPORTING],
    },
    Message: {
      Subject: { Data: `${content.localeCountry} | Eine neue "${content.testId}" Bewertung ist eingetroffen` },
      Body: {
        Html: {
          Data: markupRatingReporting(content),
        },
      },
    },
  };
  await send(message);
};

export {
  sendUserResult,
  reportTest,
  reportRating,
};
