export default {
  formSteps: {
    0: {
      question: 'Was würdest Du am liebsten unternehmen?',
      choices: {
        0: 'Ein paar Tage Spontan-Urlaub ohne festen Plan.',
        1: 'Mit engen Freunden gemeinsam kochen und gute Gespräche führen.',
        2: 'Eine Überraschungs-Party für einen lieben Menschen auf die Beine stellen.',
        3: 'Einen ruhigen Nachmittag im eigenen Haus mit Garten verbringen.',
        4: 'Den Nervenkitzel bei einer riskanten Sportart suchen.',
      },
      advices: {
        0: '<p><strong>Deine Stärke Nr. 1: Unabhängigkeit</strong><br>Menschen mit dieser Stärke scheuen nicht vor einem selbstbestimmten Lebensstil mit eigenständigen Entscheidungen zurück. Sich <strong>nicht so leicht aus der Bahn werfen</strong> zu lassen, wenn Neues auf einen zukommt – dafür braucht es innere Stärke und Mut. Und die besitzt Du anscheinend.<br>Womöglich macht Dir trotzdem der Gedanke, ein Kind zu bekommen, Angst. Vielleicht auch deshalb, weil Du Dich sorgst, dadurch Deine Selbstbestimmtheit ein Stück weit zu verlieren.<br>Gleichzeitig beinhaltet Unabhängigkeit oft auch die Fähigkeit, sich von Aufgaben und Herausforderungen <strong>nicht beengen</strong> zu lassen. Vielmehr kannst Du diese Freiheit auch auf eine Weise gebrauchen, die Dich <strong>stolz auf Deine Entscheidungen zurückblicken</strong> lässt.</p>',
        1: '<p><strong>Deine Stärke Nr. 1: Einfühlsamkeit</strong><br>Mit dieser Fähigkeit spürst Du vermutlich feinfühlig, was in anderen Menschen vorgeht und was ein anderes Herz berührt. Ebenso kannst Du mit Deinem <strong>Einfühlungsvermögen</strong> Geborgenheit und Liebe schenken. Denn Du ahnst, was es braucht, um anderen Menschen Wärme und Trost zu schenken.<br>Vielleicht fällt es Dir durch die Schwangerschaft momentan jedoch schwer, zu deuten, was sich überhaupt in Dir selbst regt. Womöglich hast Du Angst, dieser Herausforderung nicht gewachsen zu sein.<br>Doch gerade Du mit Deinem Feingefühl kannst vielleicht auch erspüren, <strong>was Du selbst brauchst,</strong> wenn schwierige Situationen auf Dich warten.</p>',
        2: '<p><strong>Deine Stärke Nr. 1: Elan</strong><br>Wenn Du eine Sache anpackst, dann geht etwas voran – Du widmest Dich Menschen und Aufgaben mit Leidenschaft. So gelingt es Dir, auch in anderen Mut zu wecken und Dinge voranzubringen.<br>Vielleicht sorgst Du Dich momentan, dass die Herausforderungen der Schwangerschaft das „Feuer“ in Dir ersticken könnten und Dein Leben dadurch an Fülle verlieren würde...<br>Gleichzeitig besitzt gerade Du mit Deiner <strong>Willensstärke</strong> die nötige Fähigkeit, um <strong>an dieser Situation zu wachsen.</strong></p>',
        3: '<p><strong>Deine Stärke Nr. 1: Ausgeglichenheit</strong><br>Als Mensch, der Ruhe schätzt, kannst Du vermutlich auch auf andere Menschen ausgleichend wirken. Denn durch Deine Umsicht und Gelassenheit können auch andere in Deiner Nähe zur Ruhe finden.<br>Vielleicht sorgst Du Dich momentan, durch die Herausforderungen dieser Schwangerschaft Deinen eigenen inneren Halt zu verlieren.<br>Gleichzeitig kannst Du Deine Stärke vielleicht aber auch gerade nutzen, um Dich <strong>so unaufgeregt wie möglich</strong> der Situation zu stellen und <strong>behutsam einen Schritt vor den anderen</strong> zu setzen. So eröffnet sich Dir immer mehr Dein ganz persönlicher Weg, auf dem Du über Dich selbst hinauswachsen kannst.</p>',
        4: '<p><strong>Deine Stärke Nr. 1: Mut</strong><br>Du liebst das Abenteuer, die Herausforderung, das Unbekannte – Du gehst bereitwillig an Deine Grenzen. Dafür bist Du bereit, einiges auf Dich zu nehmen und Deine <strong>Komfortzone zu verlassen.</strong> So bist Du auch eine <strong>Inspiration für andere,</strong> Neues zu wagen.<br>Vielleicht hast Du durch die Schwangerschaft momentan die Sorge, auf einige Erlebnisse verzichten zu müssen und dadurch manches zu verlieren, was Dich ausmacht...<br>Gleichzeitig hast gerade Du Erfahrung damit, scheinbar unüberwindbare Hürden zu nehmen. Es liegt in Deinem Naturell, Dich von solchen Herausforderungen nicht beirren zu lassen – sondern <strong>Dich Deiner Angst zu stellen.</strong> So verwandelst Du auch Schwierigkeiten in spannende Abenteuer.</p>',
      },
    },
    1: {
      question: 'Du hast gerade erfahren, dass Du aus beruflichen Gründen in eine andere Stadt umziehen musst – was ist Deine erste Reaktion?',
      choices: {
        0: 'Mir schießt durch den Kopf, was ich jetzt als nächstes tun muss: Alte Wohnung kündigen, neue Wohnung suchen, Umzug organisieren etc.',
        1: 'Die Nachricht beschäftigt mich gefühlsmäßig stark. Ich tue mir zunächst schwer, einen klaren Gedanken zu fassen.',
        2: 'Ich versuche, die neue Situation konstruktiv anzugehen – obwohl ich die Neuigkeit auch erstmal „verdauen“ muss.',
      },
      advices: {
        0: '<p><strong>Deine Stärke Nr. 2: Verstandeskraft</strong><br>Es liegt Dir, gründlich nachzudenken, alle Möglichkeiten abzuwägen und nach guten Lösungen zu suchen – das ist eine wertvolle Begabung!<br>Du nutzt Deinen Verstand, um schwierige Situationen zu bewältigen und um konkrete Schritte zu erdenken, die Dich an Dein Ziel führen. Dabei lässt Du Dich nicht von Widrigkeiten überrumpeln, sondern bewahrst einen <strong>kühlen Kopf.</strong> So hast Du die Fähigkeit, auch <strong>komplexe Probleme gut zu lösen.</strong></p>',
        1: '<p><strong>Deine Stärke Nr. 2: Intuition</strong><br>Du hast eine gute Intuition und ein feines Gespür. Dein Bauchgefühl weist spontan in eine bestimmte Richtung und zeigt Dir eine erste Tendenz an.</p>',
        2: '<p><strong>Deine Stärke Nr. 2: Ausgewogenheit</strong><br>In schwierigen Situationen achtest Du <strong>feinfühlig und umsichtig</strong> auf Verstand und Bauchgefühl. Diese Ausgewogenheit ist eine gute Basis für gereifte Entscheidungen!<br>Denn einerseits nutzt Du Deinen Verstand, um Lösungen für Probleme zu suchen und die nächsten Schritte planvoll anzugehen. Andererseits bleibst Du dabei auch offen für Deine innere Stimme und die Richtung, in die diese weist.</p>',
      },
    },
    2: {
      question: 'Wenn ich mehr Zeit in meinem Leben hätte, würde ich am liebsten...',
      choices: {
        0: 'mehr Zeit mit lieben Menschen verbringen',
        1: 'mehr Zeit in geliebte Projekte stecken und mich weiterentwickeln',
        2: 'mehr schöne Dinge in meiner Freizeit unternehmen',
        3: 'achtsamer sein, um mir selbst treu zu bleiben',
      },
      advices: {
        0: '<p><strong>Deine Stärke Nr. 3: Beziehungsfähigkeit</strong><br>Du lebst nicht nur für Dich selbst, sondern auch für andere. Es entspricht Dir zutiefst, für sie da zu sein und Dein Leben mit ihnen zu teilen. Dadurch erfährst Du tiefe Erfüllung.<br>Vielleicht kennst Du es sogar, dass Du in der Zuwendung zu anderen <strong>tiefen Sinn</strong> verspürst. Denn Du erlebst das größte Glück, wenn Du den <strong>Blick über Dich selbst hinaus</strong> hebst und Dich für andere Menschen einsetzt.</p>',
        1: '<p><strong>Deine Stärke Nr. 3: Motivation</strong><br>Du setzt Dich mit Deinen Fähigkeiten gerne für etwas ein. Dadurch prägst Du Projekte, in die Du Dich einbringst, und es kann durch Dein Zutun <strong>etwas Bedeutendes</strong> entstehen. Ganz in einer guten Sache aufzugehen, schenkt Dir Erfüllung.<br>Vielleicht kennst Du das: Dass Du die tiefste Zufriedenheit erfährst, wenn Du Dich herausfordernden Situationen stellst, um Dich <strong>für eine größere Sache einzusetzen.</strong></p>',
        2: '<p><strong>Deine Stärke Nr. 3: Begeisterungsfähigkeit</strong><br>Das Leben ist schön – und Du liebst es, diese Schönheit zu genießen. So entdeckst Du sie an allen möglichen Stellen: In der Natur, in Künstlerisch–Kreativem oder im Kennenlernen anderer Kulturen. Dieser <strong>Blick über Dich selbst hinaus</strong> und in die Besonderheiten der Welt hinein schenkt Dir tiefe innere Erfüllung.<br>Vielleicht kennst Du das: Dass Du auch in schwierigen Situationen das Schöne zur Entfaltung bringen und dadurch Zufriedenheit finden kannst.</p>',
        3: '<p><strong>Deine Stärke Nr. 3: Echtheit</strong><br>Es erfüllt Dich, in den Spiegel schauen und darauf stolz sein zu können, was Du bist und was Du tust. Du bist offensichtlich ein <strong>authentischer Mensch,</strong> der <strong>klare Wertvorstellungen</strong> hat. Daher bist Du auch bereit, Deine Überzeugungen geradlinig zu vertreten.<br>Denn vielleicht kennst Du das: Dass Du in schwierigen Situationen Erfüllung finden und über Dich selbst hinaus wachsen kannst, wenn Du Dir treu bleibst.</p>',
      },
    },
    3: {
      question: 'Wen würdest Du am ehesten mitten in der Nacht wecken, wenn Du große Sorgen hast und jemanden zum reden brauchst?',
      choices: {
        0: 'Meinen Partner',
        1: 'Meine Mutter oder meinen Vater',
        2: 'Eines meiner Geschwister oder einer meiner näheren Verwandten',
        3: 'Jemandem aus meinem Freundeskreis',
        4: 'Ich wüsste niemanden, bei dem ich mich das trauen würde',
      },
      advices: {
        0: '<p><strong>Deine Stärke Nr. 4: Partnerschaft</strong><br>Du hast einen Partner an der Seite, mit dem Du Freude und Sorgen teilen kannst. Was für eine wertvolle Unterstützung! Gemeinsam ist es einfacher, Schwierigkeiten zu bewältigen und das Bestmögliche daraus zu machen. Denn Ihr könnt Euch <strong>gegenseitig aufrichten und stützen,</strong> wenn Herausforderungen auf Euch warten.<br>Wir würden Dir wünschen, dass Ihr in dieser Situation der Schwangerschaft <strong>enger zusammenwachsen</strong> könnt – und erlebt, was alles an Wunderbarem aus tiefer Liebe entstehen kann, die zuerst den anderen sieht.</p>',
        1: '<p><strong>Deine Stärke Nr. 4: Elternbeziehung</strong><br>Es tut manchmal einfach gut, die eigenen Eltern hinter sich zu wissen – auch wenn man schon erwachsen ist! Ihr Erfahrungsschatz ist Dir sicher. Sie können Dir jetzt <strong>wertvolle Unterstützung</strong> bieten und <strong>Ratgeber</strong> sein.<br>Gerade in der momentanen Situation kann Eure Beziehung außerdem eine ganz neue Tiefe bekommen. Denn sie als Eltern kennen die verschiedenen Gefühle und Gedanken, die das Innere bewegen, wenn sich ein Kind ankündigt.</p>',
        2: '<p><strong>Deine Stärke Nr. 4: Familienbindung</strong><br>Familie und Verwandte bilden ein Netzwerk, das Dir gerade jetzt, in dieser Umbruch- Situation, <strong>Halt geben</strong> kann. Diese Menschen, die Dich Dein Leben lang schon kennen, sind bestimmt auch jetzt an Deiner Seite – mit praktischer Unterstützung und mit einem Austausch von Herz zu Herz.<br>Wir wünschen Dir, dass Dir dieses Umfeld nun Kraft schenken kann, um mit freiem Blick Deinen Weg zu finden!</p>',
        3: '<p><strong>Deine Stärke Nr. 4: Freundeskreis</strong><br>„Treue Freunde tanzen mit Dir durch den Regen, statt auf die Sonne zu warten.“ Dieser Spruch trifft vielleicht ziemlich gut Deine Situation. Du weißt, dass Deine Freunde an Deiner Seite sind.<br>Mit so treuen Weggefährten wird auch die herausforderndste Wanderung zu einem Abenteuer und einer Gelegenheit, gemeinsam zu wachsen.</p>',
        4: '<p><strong>Deine Stärke Nr. 4: Nach-Hilfe-Suchen</strong><br>Wie gut, dass Du nicht bei Deinen Sorgen stehen bleibst, sondern aktiv nach einem guten Weg suchst und dabei auf uns gestoßen bist. Sehr gerne können wir diejenigen sein, die jetzt für Dich da sind; die an Deiner Seite bleiben und mit Dir gemeinsam nach einer guten Lösung suchen. Du bist nicht alleine!<br>Wenn Du das möchtest kannst Du Kontakt zu unseren erfahrenen Beraterinnen aufnehmen. <a href="https://www.profemina.org/de-de/ueber-uns/kontaktformular">Zum Kontaktformular</a></p>',
      },
    },
    4: {
      question: 'So habe ich Probleme in der Vergangenheit gemeistert:',
      choices: {
        0: 'Ich habe versucht, das Positive in der Situation zu sehen',
        1: 'Ich habe nie mein Ziel aus den Augen verloren',
        2: 'Indem ich mir Hilfe bei anderen geholt habe',
        3: 'Indem ich in Ruhe nachgedacht habe',
        4: 'Durch mein Urvertrauen in das Leben',
        5: 'Ich hatte kein besonderes Rezept, aber irgendwie hat es immer geklappt.',
      },
      advices: {
        0: '<p><strong>Deine Stärke Nr. 5: Optimismus</strong><br>Du schaffst es, im Regen zu tanzen, anstatt auf die Sonne zu warten.<br>Dieser <strong>wache und positive Blick auf das Leben</strong> ist etwas Besonderes und Wertvolles! Gerade in der momentanen verunsichernden Situation kann diese Grundeinstellung Dir Mut machen. Denn Du hältst es für möglich, dass darin – wenn auch noch versteckt – etwas Gutes zu finden ist.<br>Wir würden Dir wünschen, dass Du mit diesem „Blick des Herzens“ auch jetzt das Bestmögliche für Dein Leben zum Vorschein bringen kannst.</p>',
        1: '<p><strong>Deine Stärke Nr. 5: Durchhaltevermögen</strong><br>Ein Ziel zu haben, gibt Dir Mut und Kraft, um den <strong>Blick nach vorne auszurichten</strong> und Dich den Herausforderungen des Lebens zu stellen. So hast Du wahrscheinlich auch schon die Erfahrung gemacht, dass Du sogar gestärkt aus schwierigen Situationen hervorgehen konntest.<br>Gerade jetzt kann Dir das den Weg zu einer tragfähigen Lösung bei Problemen mit Deiner Schwangerschaft öffnen. Denn Du kannst Dir ein Ziel festlegen – an dem Du wachsen und durch das Du diese Situation in etwas Gutes umwandeln kannst.</p>',
        2: '<p><strong>Deine Stärke Nr. 5: Offenheit</strong><br>Du weißt, wie wahr der Spruch „geteiltes Leid ist halbes Leid“ ist. Es tut Dir gut, Deine Sorgen und Gedanken mit anderen, vertrauten Menschen auf Augenhöhe zu teilen. Dabei ordnet sich Dein Inneres, und Du kannst ganz neue Blickwinkel entdecken.<br>Du hast begriffen, dass es nicht eine Schwäche ist, von anderen begleitet zu werden, sondern eine Stärke!<br>Gerade in der jetzigen Situation wünschen wir Dir, dass Du guten Rat und Unterstützung annehmen kannst! Das kann Dir auch in der momentanen Situation den Weg zu einem guten Umgang bei Problemen mit Deiner Schwangerschaft eröffnen.</p>',
        3: '<p><strong>Deine Stärke Nr. 5: Coolness</strong><br>Momente der Stille geben Dir Kraft – gerade in schwierigen Situationen, in denen Gedanken und Gefühle auf Dich einprasseln. So kann das aufgewühlte Innere nach und nach wieder in eine Ordnung zurückfinden; so lassen sich Empfindungen in die richtige Wertigkeit einsortieren.<br>Solche Zeiten der Ruhe können Dir auch in dieser Situation der Schwangerschaft den Weg zu guten Lösungen ebnen. Dadurch kannst Du Deinem Innersten auf den Grund gehen und herausfinden, welche Chance sich in der momentanen Herausforderung verbergen könnte.</p>',
        4: '<p><strong>Deine Stärke Nr. 5: Zuversicht</strong><br>Das Vertrauen, dass auch schwere Zeiten ihren Sinn haben und auch schwierige Situationen gut ausgehen können, scheint sich durch Dein Leben zu ziehen. Trotz schwieriger Erlebnisse hast Du daran festgehalten. Wahrscheinlich hast Du schon erfahren, dass auch <strong>in großen Herausforderungen etwas Gutes verborgen</strong> sein kann.<br>Gerade jetzt kann diese Wahrheit Dir helfen, zuversichtlich zu bleiben. Das gibt Dir die Chance, das Bestmögliche aus der jetzigen Situation zu machen.</p>',
        5: '<p><strong>Deine Stärke Nr. 5: Lebenskünstler</strong><br>Offensichtlich trägst Du Stärke und Mut in Dir! Ohne so genau zu wissen, was Dir immer wieder Kraft gibt, hast Du Herausforderungen, vor die das Leben Dich gestellt hat, immer gemeistert. Sonst wärst Du nicht so weit gekommen.<br>Gerade jetzt kannst Du daraus neues Vertrauen schöpfen, dass Du auch momentane Probleme mit der Schwangerschaft gut lösen können wirst. Und dass Du dabei einmal mehr über Dich selbst hinauswachsen kannst.</p>',
      },
    },
  },
};
