export default {
  formSteps: {
    0: {
      question: 'Was würde Dir jetzt gerade am meisten helfen?',
      choices: {
        0: 'Ich möchte meine Gedanken sortieren und Rat bekommen',
        1: 'Ich möchte Informationen über meine Möglichkeiten',
        2: 'Ich würde mir jemanden wünschen, der mir jetzt beisteht',
      },
      advices: {
        0: '<p><strong>Die Gedanken sortieren</strong><br>Vermutlich hat die Situation der (ungeplanten) Schwangerschaft bei Dir gerade eher für ein <strong>gedankliches Durcheinander</strong> und eine gewisse <strong>Ratlosigkeit</strong> gesorgt. Und das ist total verständlich! Eine Schwangerschaft bringt viele Veränderungen mit sich, und ganz schnell auch Verunsicherungen und Ängste.<br>Wir hoffen, dass diese digitale Beratung Dir helfen kann, <strong>klarer zu sehen.</strong> Und es wäre natürlich schön, wenn so mancher Rat für Deinen weiteren Weg eine Hilfe sein kann.</p>',
        1: '<p><strong>Informationen über alle Möglichkeiten</strong><br>Es ist immer klug, sich umfassend zu informieren, wenn eine Entscheidung ansteht. Gleichzeitig können die gesetzlichen Fristen für eine Abtreibung oder mögliche Unterstützungsmöglichkeiten für Schwangere zunächst kompliziert wirken.<br>Es würde uns freuen, wenn wir Dir im Rahmen der Digital-Beratung helfen könnten, <strong>alle Optionen in den Blick</strong> zu nehmen. Denn dann fühlen sich nach unserer Erfahrung die meisten Frauen <strong>freier und besser für eine Entscheidung gerüstet.</strong></p>',
        2: '<p><strong>Jemand, der da ist</strong><br>Eine Situation wie eine ungeplante oder problematische Schwangerschaft kann einen schnell an die persönlichen Grenzen bringen. Auch Dir geht es vermutlich ähnlich. Deshalb ist Dein Wunsch nach Menschen, die Dir beistehen, sehr verständlich.<br>Über diese Digital-Beratung hinaus kannst Du <strong>mit den <i>profemina</i>-Beraterinnen</strong> gerne <strong>individuell in Kontakt treten.</strong> Dort erfährst Du auch, wo es in Deiner Umgebung Menschen und Institutionen gibt, die Dir persönlich helfen können. Das Wichtigste können wir Dir aber jetzt schon versichern: <strong>Du musst in dieser Situation nicht alleine bleiben!</strong></p>',
      },
    },
    1: {
      question: 'Ich bin in der ... Schwangerschaftswoche.',
      choices: {
        0: 'Ich weiß es noch nicht',
        1: 'Vor der 8. Woche',
        2: '8. bis 14. Woche',
        3: 'Über der 14. Woche',
      },
      advices: {
        0: '<p><strong>Die Schwangerschaftswoche</strong><br>Du hast vermutlich erst vor kurzem von Deiner Schwangerschaft erfahren. Womöglich hast Du bei Deinem Frauenarzt schon einen Termin vereinbart oder bist kurz davor. Er kann herausfinden, wie weit Du tatsächlich bist und ob die Schwangerschaft intakt ist.<br>Dir bleibt jetzt genug Zeit für eine Entscheidung. Du kannst also, bis Du mehr weißt, <strong>erst einmal noch durchatmen.</strong> Genau das tut den meisten Frauen rückblickend sehr gut. Gerade deshalb raten wir Dir, Dich jetzt <strong>nicht unter Druck setzen zu lassen.</strong></p>',
        1: '<p><strong>Die Schwangerschaftswoche</strong><br>Du bist noch recht früh in der Schwangerschaft. Das bedeutet, dass <strong>Dir noch einiges an Zeit bleibt</strong>, um eine Entscheidung zu treffen. Du kannst also erst einmal noch durchatmen.<br>Genau dieses Durchatmen und sich etwas Zeit geben tut den meisten Frauen rückblickend sehr gut. Gerade deshalb raten wir Dir, Dich jetzt <strong>nicht unter Druck setzen zu lassen.</strong></p>',
        2: '<p><strong>Die Schwangerschaftswoche</strong><br>Du hast inzwischen schon einige Wochen der Schwangerschaft durchlebt.<br>Vielleicht bist Du schon recht nah am Ende der Frist für die Entscheidung bzgl. einer Abtreibung und hast nun das Gefühl, innerhalb weniger Tage oder gar Stunden entscheiden zu müssen? Es ist ratsam, wenn Du Dir noch Zeit gibst, solange noch Zweifel und Unsicherheit in Dir sind. <strong>Du bist es wert, eine Entscheidung zu suchen,</strong> hinter der Du mit ganzem Herzen stehen kannst.</p>',
        3: '<p><strong>Die Schwangerschaftswoche</strong><br>Das erste Drittel der Schwangerschaft liegt bereits hinter Dir. Doch Du scheinst Dir noch unsicher zu sein, wie es für Dich weitergehen kann. Wie gut, dass Du Dich deshalb auf die Suche nach Lösungswegen gemacht hast!<br>Vielleicht ist eine Abtreibung in Deinem Fall jetzt schon nicht mehr möglich. In Deutschland ist ein Schwangerschaftsabbruch in der Regel bis zur 14. SSW möglich, in der Schweiz bis zur 12. SSW und in Österreich bis zur 16. SSW.<br>Womöglich beruhigt Dich diese Nachricht, weil sie Dir die Entscheidung „Abtreiben – ja oder nein“ gewissermaßen abnimmt. Vielleicht hast Du deshalb aber auch erst recht große Sorgen. In jedem Fall hoffen wir, dass Du hier Impulse findest, die Dir in Deiner Situation weiterhelfen.</p>',
      },
    },
    2: {
      question: 'Wie sehr bestimmt die Schwangerschaft Deinen Alltag?',
      choices: {
        0: 'Ich versuche, Gedanken an meine Schwangerschaft eher wegzuschieben.',
        1: 'Ich muss ständig daran denken, dass ich schwanger bin.',
        2: 'Ich denke schon darüber nach, aber finde auch den Raum für andere Gedanken.',
      },
      advices: {
        0: '<p><strong>Gedanken – wie kann es weitergehen?</strong><br>Bisher hast Du versucht, nicht zu viel über die Schwangerschaft nachzugrübeln. Es ist verständlich und auch wichtig, dass Du Dich von dem Gedanken und den Sorgen nicht komplett vereinnahmen lässt.<br>Gleichzeitig ist es auch gut, dass Du Dir nun in einem gesunden Maße Gedanken machst, wie es weitergehen kann und Dich hier nach guten Lösungen umsiehst.</p>',
        1: '<p><strong>Gedanken – wie kann es weitergehen?</strong><br>Momentan beschäftigt Dich die Schwangerschaft stark und Du findest wohl kaum Ruhe zwischen den vielen Gedanken und Sorgen. Wahrscheinlich ist das anstrengend und aufreibend für Dich.<br>Grundsätzlich ist es natürlich wichtig, sich Gedanken zu machen. Für eine gute Entscheidung, die man mit <strong>freiem Blick</strong> trifft, hilft es aber auch, immer wieder ganz <strong>bewusst aus dem Gedanken-Karussell auszusteigen</strong> und etwas abzuschalten. Vielleicht gelingt es Dir, Dir solche Gedanken-Pausen zu ermöglichen, um einmal durchatmen zu können.</p>',
        2: '<p><strong>Gedanken – wie kann es weitergehen?</strong><br>Du hast ein gutes Mittelmaß gefunden, wie Du gedanklich mit der Schwangerschaft und allem, was Dich dazu bewegt, umgehen kannst. So machst Du Dir zwar Gedanken darüber, was wichtig und natürlich ist. Du steigst aber auch immer wieder bewusst aus dem Gedanken-Karussell aus, um <strong>durchzuatmen und einen freien Blick</strong> zu bekommen.<br>Das gelingt nicht jedem; und es wird Dir dabei helfen, eine gereifte Entscheidung zu treffen.</p>',
      },
    },
    3: {
      question: 'Meine erste Reaktion auf die Schwangerschaft:',
      choices: {
        0: 'Angst',
        1: 'Schock',
        2: 'Freude',
        3: 'Chaos der Gefühle',
      },
      advices: {
        0: '<p><strong>Erste Reaktion: Angst</strong><br>Die Nachricht von Deiner Schwangerschaft scheint sich im ersten Moment wie ein <strong>dunkler Schleier</strong> über Dich gelegt zu haben. Diese Gefühle und Gedanken sind absolut in Ordnung und es geht vielen Frauen so!<br>Es ist ein Prozess, bis Du den Schleier ein wenig anheben und <strong>wieder klarer sehen</strong> kannst. Dabei sollte man die hormonelle Umstellung zu Beginn der Schwangerschaft nicht unterschätzen. Denn das <strong>veränderte Körperempfinden</strong> kann einem ganz schön zu schaffen machen, und das spürt man auch emotional. Nach einigen Wochen klingt das in aller Regel jedoch wieder ab.</p>',
        1: '<p><strong>Erste Reaktion: Schock</strong><br>Die Nachricht von der Schwangerschaft scheint Dich richtiggehend überrollt zu haben und hat Dich in Schock versetzt. Das geht vielen Frauen so und ist angesichts dieser großen Neuigkeit auch nicht ungewöhnlich.<br>Neben der Überraschung stellt vermutlich auch die <strong>hormonelle Umstellung</strong> während der Frühschwangerschaft emotional so manches auf den Kopf; das sollte nicht unterschätzt werden. Das <strong>veränderte Körperempfinden</strong> kann einem ganz schön zu schaffen machen, und das spürt man auch emotional. Nach einigen Wochen klingt das in aller Regel jedoch wieder ab.</p>',
        2: '<p><strong>Erste Reaktion: Freude</strong><br>Wie schön, dass Du Dich erst einmal über die Schwangerschaft freuen konntest! Auch wenn inzwischen wohl so manche Sorge aufgetaucht ist: Die erste Reaktion spricht schon eine Sprache. Denn zu Beginn hast Du Deine Gefühle wohl noch ohne den Filter Deiner Fragen und Ängste wahrgenommen.<br>Es ist ratsam, auch diesem Teil von Dir Raum zu schenken – und hin und wieder zu überlegen: Was hat mich da eigentlich so gefreut am Anfang?</p>',
        3: '<p><strong>Erste Reaktion: Chaos der Gefühle</strong><br>Dass Dich zunächst viele unterschiedliche Gedanken und Gefühle bewegt haben, ist ganz normal. Zu Beginn einer Schwangerschaft macht sich die <strong>hormonelle Umstellung</strong> und das veränderte Körperempfinden emotional oft ziemlich bemerkbar; das sollte nicht unterschätzt werden.<br>Es braucht seine Zeit, bis sich Dein Körper und auch Dein Denken mit der Situation auseinandergesetzt haben und sich darauf einstellen können. In dieser Phase können auch Deine Gefühle durcheinander und sprunghaft sein. Nach einigen Wochen <strong>kehrt</strong> aber meist <strong>innerlich wieder Ruhe ein,</strong> und so kann sich mit der Zeit in Deinem Körper und auch emotional vieles wieder ordnen.</p>',
      },
    },
    4: {
      question: 'Was könnte mein nächster Schritt sein?',
      choices: {
        0: 'Ich würde gerne mit einer neutralen Person sprechen.',
        1: 'Ich möchte mich einem lieben Menschen anvertrauen.',
        2: 'Ich weiß noch gar nicht, was ich tun soll.',
        3: 'Ich denke daran, konkrete Schritte in Richtung Abtreibung zu gehen.',
      },
      advices: {
        0: '<p><strong>Als nächstes: Neutrale Ansprechperson</strong><br>Gerade in einer Situation, in der emotional vieles durcheinander ist, tut ein neutraler Ansprechpartner, der sich auskennt, oft gut. Der Blick von außen kann ganz <strong>neue Blickwinkel</strong> auf die Situation ermöglichen.<br>Falls Du <strong>noch auf der Suche</strong> nach einem solchen Ansprechpartner bist, kannst Du Dich gerne an unsere erfahrenen Beraterinnen wenden! <a href="https://www.profemina.org/de-de/ueber-uns/kontaktformular">Zum Kontaktformular</a></p>',
        1: '<p><strong>Als nächstes: Gespräch mit einem lieben Menschen</strong><br>Vielleicht denkst Du dabei an Deinen Partner, an Deine Mama oder eine liebe Freundin – auf jeden Fall tut es gerade in einer Ausnahmesituation gut, sich einem Menschen anzuvertrauen, bei dem man sich <strong>fallen lassen</strong> kann und dem man <strong>vertraut.</strong><br>Wir wünschen Dir für dieses Gespräch, dass Du dabei ganz <strong>offen und frei</strong> über das sprechen kannst, was Dich bewegt und auf Deinem, <strong>für Dich passenden Weg begleitet</strong> wirst.</p>',
        2: '<p><strong>Als nächstes: Keine Ahnung?!</strong><br>Momentan siehst Du gar keinen nächsten Schritt vor Dir, der Dir jetzt weiterhelfen könnte. Könnte es Dir vielleicht gut tun, mit einem lieben Menschen ins Gespräch zu kommen? Oder würde es Dir helfen, beim Frauenarzt genauere Infos zu bekommen, wie es um die Schwangerschaft gerade steht?<br>Falls Du gerne mit jemandem gemeinsam überlegen würdest, was die nächsten Schritte sein könnten, kannst Du Dich gern an unsere erfahrenen Beraterinnen wenden! <a href="https://www.profemina.org/de-de/ueber-uns/kontaktformular">Zum Kontaktformular</a></p>',
        3: '<p><strong>Die nächsten Schritte</strong><br>Momentan scheint Dir der Weg der Abtreibung am naheliegendsten und Du planst die nächsten Schritte in diese Richtung.<br>Vielleicht bist Du Dir auch noch nicht vollständig sicher – und bist gleichzeitig auch noch offen für Lösungen „mit Kind“. Es ist gut, wenn Du auch weiterhin auf alles achtest, was in Deinem Inneren vor sich geht. Bis zuletzt hast Du die Freiheit, auch den leiseren Stimmen in Dir Gehör zu schenken und sie in Deine Entscheidung einzubeziehen.</p>',
      },
    },
    5: {
      question: 'Wie könntest Du in Deiner jetzigen Situation am ehesten zur Ruhe kommen?',
      choices: {
        0: 'Ich sehe Licht am Ende des Tunnels',
        1: 'Zuhause auf meiner weichen Couch, wo ich sein kann, wer ich bin',
        2: 'Ich bin in der Natur und atme frei durch',
        3: 'Ich stehe auf einem Berggipfel und kann alles von oben erkennen',
        4: 'Ich habe liebe Menschen um mich, die für mich da sind',
      },
      advices: {
        0: '<p><strong>Licht am Ende des Tunnels</strong><br>Die Last Deiner Sorgen bezüglich der Schwangerschaft bedrücken Dich sehr. Wenn in dieser Situation nun ein Licht aufleuchten würde und Du spüren würdest, dass diese dunkle Phase auch ein Ende finden kann, könntest Du durchatmen und neuen Mut fassen.<br>Du kannst Dich deshalb fragen: Was wäre für mich konkret ein Licht in dieser Situation? Wo sehe ich schon jetzt einen solchen kleinen Lichtschimmer? Und was könnte ich tun, damit diese „Lichtmomente“ mehr werden?</p>',
        1: '<p><strong>Mein Ort der Ruhe: Zuhause auf der Couch</strong><br>Die Schwangerschaft ist momentan für Dich etwas Unbekanntes, Neues, und das verunsichert Dich – ein wenig liegt das in der Natur der Sache. Für Dich wäre es erleichternd, wenn Du einen Ort der Sicherheit und der Geborgenheit finden könntest. Von dort aus lässt sich vieles leichter bewältigen.<br>Du kannst Dich deshalb fragen: Was ist solch ein sicherer Ort für mich? Wo oder mit wem erlebe ich auch jetzt im Moment Augenblicke, in denen ich dieses Gefühl der Geborgenheit habe? Und was könntest ich konkret tun, um diese Momente gerade jetzt öfter zu erleben?</p>',
        2: '<p><strong>In der Natur durchatmen</strong><br>Die Schwangerschaft löst in Dir momentan das Gefühl aus, nicht ganz frei atmen zu können und vielleicht in den Umständen ein wenig eingesperrt zu sein. In die Natur zu gehen und dort einfach durchatmen zu können, würde Dich entlasten und vermutlich den Blick öffnen.<br>Kannst Du in dieser Situation öfter einmal in die Natur gehen, um dieses Gefühl und den nötigen Abstand zu bekommen? Wo erlebst Du sonst im Alltag auch gerade solche Momente des Durchatmens? Und was kannst Du tun, um diese Augenblicke öfter zu erleben?</p>',
        3: '<p><strong>Auf dem Berggipfel – Vogelperspektive</strong><br>Du fühlst Dich in der momentanen Situation vermutlich gefangen. Es fällt schwer, in all den Sorgen den Weitblick zu bewahren und aus einer gewissen Vogelperspektive auf die Situation zu blicken. Doch gerade das könnte Dir jetzt Erleichterung verschaffen.<br>Gibt es auch aktuell Momente, in denen Du diese Vogelperspektive und diesen freien Blick erlebst? Oder Menschen, die Dir dabei helfen? Und was könntest Du konkret tun, um solche Augenblicke gerade jetzt öfter zu erleben?</p>',
        4: '<p><strong>Unter lieben Menschen</strong><br>Die Schwangerschaft und die Sorgen bedrücken Dich und Du hast womöglich das Gefühl, einsam zu sein damit. Doch es würde Dir helfen, liebe Menschen um Dich herum zu haben, um Zuversicht zu schöpfen und vielleicht Kraft und Mut zu bekommen.<br>Wer sind solche lieben Menschen, die Dich aufbauen? Was könntest Du konkret tun, um gerade jetzt mehr Kontakt zu diesen Personen zu pflegen?</p>',
      },
    },
    6: {
      question: 'Für die Zukunft wünsche ich mir:',
      choices: {
        0: 'Dass ich die schönen Seiten meines Lebens bewahren kann.',
        1: 'Dass ich eine Entscheidung fällen kann, mit der es mir langfristig gut geht.',
        2: 'Dass ich Freude über meine Schwangerschaft empfinden kann.',
      },
      advices: {
        0: '<p><strong>Das Schöne im Leben</strong><br>Ein absolut verständlicher Wunsch: Dass Du das Schöne im Leben durch Deine Entscheidung nicht verlieren möchtest. Das, was das Leben lebenswert und reich macht.<br>Was genau sind die schönen Seiten Deines Lebens und wie kannst Du sie erhalten? Könnte aus dieser Situation sogar noch mehr und anderes Schönes hervorgehen, als Du bisher kennst?</p>',
        1: '<p><strong>Eine gute Entscheidung</strong><br>Jeder kennt Situationen im Leben, in denen man vor einer Weggabelung steht. Und da ist der Wunsch verständlich, dass man im Nachhinein nicht damit hadern möchte, wie man sich damals entschieden hat – sondern dass man <strong>stolz auf die Entscheidung zurückblicken</strong> kann. Gleichzeitig ist der vermeintlich einfachere Weg nicht immer automatisch der richtige.<br>Vielleicht bist Du Dir schon ziemlich sicher, dass Du bald zu solch einer guten Entscheidung kommen wirst. Vielleicht wünschst Du Dir dazu aber auch noch <strong>Input von unabhängiger Seite</strong> – dann könnte unser <a href="https://www.profemina.org/abtreibung/abtreibungstest">Abtreibungstest</a> Dir vielleicht helfen.</p>',
        2: '<p><strong>Freude über die Schwangerschaft</strong><br>Vielleicht hast Du ganz am Anfang eine zarte Freude empfunden. Doch auch die anderen Gefühle sind nun da, und das darf auch sein. Die kritischeren Gefühle zeigen auch, dass Du Dir <strong>Deiner Verantwortung bewusst</strong> bist und Dir deswegen viele Gedanken machst.<br>Letztlich <strong>zählt ja nicht nur das, was Du fühlst</strong> (darauf haben wir oft keinen Einfluss), sondern vor allem, <strong>was Du daraus machst.</strong> Die Gefühle folgen dann meistens in ihrem eigenen Tempo, wenn eine bewusste Entscheidung getroffen wurde.</p>',
      },
    },
  },
};
