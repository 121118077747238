module.exports = function markup(content) {
  const mailBody = `<!doctype html>
  <html>
    <head>
      <meta name="viewport" content="width=device-width">
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
      <title>Profemina e.V.</title>
      <style>
        body {
          font-family: sans-serif;
          -webkit-font-smoothing: antialiased;
          font-size: 16px;
          line-height: 1.4;
          margin: 0;
          padding: 12px;
          max-width: 580px;
          -ms-text-size-adjust: 100%;
          -webkit-text-size-adjust: 100%;
        }
        p.rating {
          color: #55855D;
        }
        p.footer {
          margin-top: 12px;
          font-size: 12px !important;
          color: #999999;
        }
      </style>
    </head>
    <body>
    <p>Hallo liebes Digitalteam,</p>
    <p>ein neuer "${content.testId}"-Test hat eine neue Bewertung :)</p>
    <p>Sprache-Land Code: ${content.localeCountry}</p>
    <p class="rating">Sterne: ${content.data.stars}</p>
    <p class="rating">${content.data.text}</p>
    <p class="footer">From Elea with ❤️ </p>
    </body>
  </html>
  `;
  return mailBody;
};
