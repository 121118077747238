import messages from '@/locales/de/messages';
import get from 'lodash.get';

const getReportAdditive = (formSteps, choiceJourney) => {
  const data = choiceJourney.map((choiceIdx, i) => (
    `<br><p>${i + 1}: ${formSteps[i].question}</p>
    <p><strong>${formSteps[i].choices[choiceIdx]}</strong></p>`
  )).join('');
  return data;
};

const getReportPathDependent = (formSteps, choiceJourney) => {
  function generateString(journey, choiceIdx, i) {
    const journeyShort = journey.slice(0, i + 1);
    const questionObj = formSteps[i].question;
    const question = i === 0 ? questionObj : get(questionObj, journeyShort.slice(0, -1));
    const choiceObj = formSteps[i].choices;
    const choice = i === 0 ? choiceObj[choiceIdx] : get(choiceObj, journeyShort);
    return `<br><p>${i + 1}: ${question}</p><p><strong>${choice}</strong></p>`;
  }
  const data = choiceJourney.map((choiceIdx, i) => (
    generateString(choiceJourney, choiceIdx, i)
  )).join('');
  return data;
};

const getReport = (choiceJourney, id, additive) => {
  const { formSteps } = messages[id];
  return additive
    ? getReportAdditive(formSteps, choiceJourney)
    : getReportPathDependent(formSteps, choiceJourney);
};

const createReportingPayload = (id, localeCountry, data) => {
  const testId = messages[id].title;
  const payload = {
    testId,
    localeCountry,
    data,
  };
  return payload;
};

export {
  getReport,
  createReportingPayload,
};
