import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import themes from './themes';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify);

const opts = {
  iconfont: 'mdi',
  theme: {
    light: true,
    themes,
  },
};

export default new Vuetify(opts);
